<template>
  <!-- couldManage -->
  <div>
     <myheader></myheader>
      <div class="img-header">
        <img src="../../../src/assets/jspt.jpg"/>
      </div>
    <!-- Css用-，方法，变量用小驼峰， -->
    <div class="out">
      <div class="plot-warp-up">
        <div class="plot">平台简讯</div>
        <div class="line-blue"></div>
        <div class="pc">
          <div class="pc-img"><img style="width:500px;height: 300px;" src="@/assets/m1.jpg" alt="" /></div>
          <div class="pc-content1">
            公司根据多年支持云计算赛项的经验，借鉴世界技能大赛的竞赛规则，率先推出混合云竞赛模式。
            以私有云运维、云运维开发两个模块对学生私有云的技能掌握情况进行考查，以云应用部署、云应用运维两个模块对学生公有云的技能知识进行考核。
          </div>
        </div>
      </div>
    </div>
    <div class="two" hidden>
      <div class="could-zujian">
        <div class="could-zujian-title">核心功能</div>
        <div class="line-blue"></div>

        <div class="could-zujian-content">
          目前公有云组件支持接入的公有云厂商包括华为云、腾讯云、阿里云、百度云及亚马逊。公有云资源组件具体管理包括公有云资
          源访问配置、云主机生命周期管理、云主机状态管理、云主机批量操作以及公有云镜像管理等功能。
        </div>
        <div class="could-zujian-img"><img src="@/assets/couldManage/could.jpg" alt="" /></div>
      </div>
    </div>
    <div class="out" hidden>
      <div class="plot-warp-up">
        <div class="plot">云管平台私有云组件</div>
        <div class="line-blue"></div>
        <div class="pc">
          <div class="pc-img"><img src="@/assets/couldManage/openStack.jpg" alt="" /></div>
          <div class="pc-content">
            私有云组件主要是对Openstack和Kubernetes进行 管理。对于K8s，提供集群管理、命名空间、K8S实 例的管理功能;对于Openstack，提供集群管理、实 例管理、网络管理、镜像管理的管理功能。
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="out" hidden>
        <div class="plot-warp-up">
          <div class="plot">服务器组管理</div>
          <div class="line-blue"></div>
          <div class="pc">
            <div class="pc-content">
              服务器组管理主要是针对服务器组分类，创建服务器 组的时候可以使用不同的类型加以区分，编辑服务器 信息、并跳转到iumpserver进行字符界面控制。提供用户、用户组进行单独或者批量的授权，可以按节
              点、按资产进行授权。
            </div>
            <div class="pc-img"><img src="@/assets/couldManage/fuwuq.jpg" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="out" hidden>
        <div class="plot-warp-up">
          <div class="plot">公有云生命周期管理</div>
          <div class="line-blue"></div>
          <div class="pc">
            <div class="pc-img"><img src="@/assets/couldManage/couldPublic.jpg" alt="" /></div>
            <div class="pc-content">生命周期管理是指基于不同公有云厂商提供的云主机 控制API对该厂商配置账户下的云主机全生命周期管 理。主要包括弹性云服务器的创建、删除、修改、查询等。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ji-qun">
      <div>
        <div class="plot">平台特色</div>
        <div class="line-blue"></div>
        <img class="ji-qun-img" src="@/assets/couldManage/jicheng.jpg" alt="" />
        <div class="two-piece">
          <div class="piece">
            <div class="jiqun-title">数据大屏展示实时得分</div>
            <div class="jiqun-content">参赛队伍总排名、 当前赛程排名、 实时计分明细，使比赛数据公开透明</div>
          </div>
          <div class="piece2">
            <div class="jiqun-title">竞赛方式灵活多变</div>
            <div class="jiqun-content">平台提供团队赛和个人赛两种竞赛模式，团队赛支持团队成员在线协作共同完成—份竞赛试题，提交后自动判分，且实时推送答题记录</div>
          </div>
        </div>
        <div class="two-piece">
          <div class="piece">
            <div class="jiqun-title">国际化配置支持中英文切换</div>
            <div class="jiqun-content">答题界面支持中英文切换，题库支持全英文，与国际接轨</div>
          </div>
          <div class="piece2">
            <div class="jiqun-title">组卷方式简单便捷</div>
            <div class="jiqun-content">支持命令行检测、 公有云服务检测、 主观题检测和客观题检测，用户可以根据业务需要自定义题型、自由组卷，全面满足竞赛方向不同层次的需求</div>
          </div>
        </div>
      </div>
    </div>
    <div id="peyms" class="content-Box wow animate__fadeInUp">
                <!-- 培养模式的名字-->
                <div class="title1 wow animate__fadeInUp">
                    {{ trainingMode.name }}
                </div>
                <!-- 培养模式的内容-->
                <div class="content-box2">
                    {{ trainingMode.content }}
                </div>
                <!-- 培养模式的图片1-->
                <div class="commonly">
                    <div class="commonly2">
                        <img :src="trainingMode.url" alt="" />
                    </div>
                    <!-- 培养模式的图片1-->
                    <div class="commonly1">
                        <img :src="trainingMode.url1" alt="" />
                    </div>
                </div>
    </div>
     <mybottom></mybottom> 
  </div>
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {

    components:{myheader,mybottom},
  data() {
    return {
      trainingMode: {
                name: '应用场景',
                content: '在历届云计算赛项中，公司竞赛平台全力为各省市提供优质的赛项技术服务支持。',
                url: require('../../../src/assets/2.png'),
                url1: require('../../../src/assets/ping.png'),
            },
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
  .content-Box {
    margin-top: 50px;
}
.title1 {
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-weight: 600;
    font-size: 30px;
    padding-top: 65px;
    text-align: center;
}
.content-box1 {
    width: 1200px;
    height: 280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 3px #e4dcdc;
    margin-top: 50px;
    display: flex;
}
.content-box2 {
    width: 830px;
    height: 100px;
    margin: auto;
    font-size: 16px;
    color: black;
    margin-top: 45px;
    justify-content: center;
    letter-spacing: 0.8px;
    line-height: 2;
}
.content-Box {
    margin-top: 30px;
}

.commonly {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.commonly1 {
    margin-left: 75px;
    width: 300px;
    height: 200px;
}
.commonly1 img{
    width: 300px;
    height: 200px;
}
.commonly2 img{
    width: 300px;
    height: 200px;
}

.img-header {
    margin: 0 auto;
    width: 100%;
    min-width: 1200px;
    max-width: 1920px;
    /* height: 420px; */
    /* background: url('../../../src/assets/backImg.png'); */
    /* background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size:contain; */
    /* background-origin: 50%; */
    /* background-size: 100%; */
    img{
      width: 100%;
    }
}
.out {
  margin: 0 auto;
  width: 1200px;
  /* text-align: center; */
  /* display: flex;
    justify-content: center; */
}
.plot {
  font-size: 30px;
  font-weight: 800px;
  display: flex;
  justify-content: center;
  padding-top: 70px;
  color: black;
}
.line-blue {
  background-color: #2e71fe;
  width: 60px;
  height: 3px;
  border-radius: 5px;
  margin-top: 25px;
  margin-left:auto ;
  margin-right:auto ;
}

.plot-warp-up {
  height: 552px;
  width: 1200px;
}
.pc {
  display: flex;
  margin-top: 80px;
}
.pc-img {
  margin-left: 80px;
}
.pc-content {
  font-size: 18px;
  width: 600px;
  margin-top: 35px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  color: #000;
  line-height: 35px;
}
.pc-content1 {
  font-size: 18px;
  width: 600px;
  margin-top: 35px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  color: #000;
  text-indent: 2em;
  line-height: 35px;
}
.server {
  width: 1200px;
  height: 550px;
  /* background-color: #f9f9f9; */
  text-align: center;
  margin: 0px auto;
}
.two {
  /* text-align: center; */
  width: 100%;
  min-width: 1200px;
  background-color: #f9f9f9;
  height: 650px;
  display: flex;
  justify-content: center;
}
.could-zujian {
  width: 1200px;
  margin: 0 auto;
}
.could-zujian-title {
  font-size: 30px;
  color: black;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.could-zujian-content {
  width: 900px;
  font-size: 16px;
  margin: 0 auto;
  margin-top: 35px;
  color: black;
  line-height: 30px;
}
.could-zujian-img {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
.ji-qun {
  width: 100%;
  min-width: 1200px;
  height: 690px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  position: relative;
}
.two-piece {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.center{
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
.piece {
  width: 423px;
  height: 180px;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgb(220, 219, 219);
}
.piece2 {
  width: 423px;
  height: 180px;
  background-color: #fff;
  margin-left: 350px;
  box-shadow: 0px 0px 20px rgb(220, 219, 219);
}
.jiqun-title {
  color: #2e71fe;
  font-size: 18px;
  margin-top: 30px;
  font-weight: 500;
  margin-left: 20px;
}
.jiqun-content {
  font-size: 16px;
  color: #000;
  margin-left: 20px;
  margin-top: 10px;
  width: 370px;
  line-height: 30px;
}
.ji-qun-img {
  position: absolute;
  margin-left: 480px;
  margin-top: 100px;
}
</style>

